import React from "react";
import Dashboard from "../../../components/common/Layout/Dashboard";
import FourCardRow from "../../../components/common/Layout/FourCardRow";
import FourGraphs from "../../../components/common/Layout/FourGraphs";
import InfoCard from "../../../components/common/InfoCard";
import SimplePieChart from "../../../components/common/Charts/PieChart";
import LineBarChart from "../../../components/common/Charts/LineBarChart";
import CustomBarChart from "../../../components/common/Charts/CustomBarChart";
import ContentCard from "../../../components/common/ContentCard";
import SimpleTable from "../../../components/common/Charts/SimpleTableChart";
import { Box, Card, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const pieChartData = {
  unitMeasure: "",
  data: [
    { name: "Inventory Summary", value: 60 },
    { name: "Sales Performance", value: 20 },
    { name: "Vendor Performance", value: 20 },
  ],
};

const customBarChartData = {
  unitMeasure: "lbs",
  data: [
    {
      day: 1,
      month: 1,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 2,
      month: 1,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 3,
      month: 1,
      year: 2023,
      upcoming: 9200,
      started: 10000,
      completed: 4500,
      adjusted: 6800,
    },
    {
      day: 4,
      month: 1,
      year: 2023,
      upcoming: 6800,
      started: 7800,
      completed: 3200,
      adjusted: 4800,
    },
    {
      day: 5,
      month: 1,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 6,
      month: 1,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 7,
      month: 1,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 8,
      month: 1,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 9,
      month: 1,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 10,
      month: 1,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 11,
      month: 1,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 12,
      month: 1,
      year: 2023,
      upcoming: 8800,
      started: 9800,
      completed: 4300,
      adjusted: 6400,
    },
    {
      day: 13,
      month: 1,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3700,
      adjusted: 5500,
    },
    {
      day: 14,
      month: 1,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 15,
      month: 1,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 16,
      month: 1,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 17,
      month: 1,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 18,
      month: 1,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 19,
      month: 1,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 20,
      month: 1,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 21,
      month: 1,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 22,
      month: 1,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 23,
      month: 1,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 24,
      month: 1,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 25,
      month: 1,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 26,
      month: 1,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 27,
      month: 1,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 28,
      month: 1,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 29,
      month: 1,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 30,
      month: 1,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 31,
      month: 1,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 1,
      month: 2,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 2,
      month: 2,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 3,
      month: 2,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 4,
      month: 2,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 5,
      month: 2,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 6,
      month: 2,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 7,
      month: 2,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 8,
      month: 2,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 9,
      month: 2,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 10,
      month: 2,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 11,
      month: 2,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 12,
      month: 2,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 13,
      month: 2,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 14,
      month: 2,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 15,
      month: 2,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 16,
      month: 2,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 17,
      month: 2,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 18,
      month: 2,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 19,
      month: 2,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 20,
      month: 2,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 21,
      month: 2,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 22,
      month: 2,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 23,
      month: 2,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 24,
      month: 2,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 25,
      month: 2,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 26,
      month: 2,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 27,
      month: 2,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 28,
      month: 2,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 1,
      month: 3,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 2,
      month: 3,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 3,
      month: 3,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 4,
      month: 3,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 5,
      month: 3,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 6,
      month: 3,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 7,
      month: 3,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 8,
      month: 3,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 9,
      month: 3,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 10,
      month: 3,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 11,
      month: 3,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 12,
      month: 3,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 13,
      month: 3,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 14,
      month: 3,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 15,
      month: 3,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 16,
      month: 3,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 17,
      month: 3,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 18,
      month: 3,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 19,
      month: 3,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 20,
      month: 3,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 21,
      month: 3,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 22,
      month: 3,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 23,
      month: 3,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 24,
      month: 3,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 25,
      month: 3,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 26,
      month: 3,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 27,
      month: 3,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 28,
      month: 3,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 29,
      month: 3,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 30,
      month: 3,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 31,
      month: 3,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 1,
      month: 4,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 2,
      month: 4,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 3,
      month: 4,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 4,
      month: 4,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 5,
      month: 4,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 6,
      month: 4,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 7,
      month: 4,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 8,
      month: 4,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 9,
      month: 4,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 10,
      month: 4,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 11,
      month: 4,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 12,
      month: 4,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 13,
      month: 4,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 14,
      month: 4,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 15,
      month: 4,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 16,
      month: 4,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 17,
      month: 4,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 18,
      month: 4,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 19,
      month: 4,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 20,
      month: 4,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 21,
      month: 4,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 22,
      month: 4,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 23,
      month: 4,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 24,
      month: 4,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 25,
      month: 4,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 26,
      month: 4,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 27,
      month: 4,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 28,
      month: 4,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 29,
      month: 4,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 30,
      month: 4,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 1,
      month: 5,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 2,
      month: 5,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 3,
      month: 5,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 4,
      month: 5,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 5,
      month: 5,
      year: 2023,
      upcoming: 7200,
      started: 8200,
      completed: 3600,
      adjusted: 5200,
    },
    {
      day: 6,
      month: 5,
      year: 2023,
      upcoming: 9500,
      started: 10500,
      completed: 4800,
      adjusted: 7000,
    },
    {
      day: 7,
      month: 5,
      year: 2023,
      upcoming: 8000,
      started: 9000,
      completed: 4000,
      adjusted: 6000,
    },
    {
      day: 8,
      month: 5,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
    {
      day: 9,
      month: 5,
      year: 2023,
      upcoming: 9200,
      started: 10200,
      completed: 4600,
      adjusted: 6800,
    },
    {
      day: 10,
      month: 5,
      year: 2023,
      upcoming: 7800,
      started: 8800,
      completed: 3900,
      adjusted: 5800,
    },
    {
      day: 11,
      month: 5,
      year: 2023,
      upcoming: 8500,
      started: 9500,
      completed: 4200,
      adjusted: 6200,
    },
    {
      day: 12,
      month: 5,
      year: 2023,
      upcoming: 7000,
      started: 8000,
      completed: 3400,
      adjusted: 5000,
    },
    {
      day: 13,
      month: 5,
      year: 2023,
      upcoming: 9000,
      started: 10000,
      completed: 4400,
      adjusted: 6600,
    },
    {
      day: 14,
      month: 5,
      year: 2023,
      upcoming: 8200,
      started: 9200,
      completed: 4100,
      adjusted: 6100,
    },
    {
      day: 15,
      month: 5,
      year: 2023,
      upcoming: 7500,
      started: 8500,
      completed: 3800,
      adjusted: 5500,
    },
  ],
};

const employeeActivityData = {
  columns: [
    { id: "recentlyJoined", label: "Inventory Stock Level Report" },
    { id: "inTraining", label: "Sales Performance Report" },
    { id: "birthdays", label: "Employee Attendance Report" },
  ],
  rows: [
    {
      id: 1,
      recentlyJoined: "Generated yesterday",
      inTraining: "Generated 2 days ago",
      birthdays: "Generated last week",
      reviewPending: "William Nguyen",
      onLeave: "David Rodriguez",
    },
    {
      id: 2,
      recentlyJoined: "",
      inTraining: "",
      birthdays: "",
      reviewPending: "",
      onLeave: "",
    },
    {
      id: 3,
      recentlyJoined: "",
      inTraining: "",
      birthdays: "",
      reviewPending: "",
      onLeave: " ",
    },
    {
      id: 4,
      recentlyJoined: " ",
      inTraining: "",
      birthdays: "",
      reviewPending: "",
      onLeave: "",
    },
    {
      id: 5,
      recentlyJoined: "",
      inTraining: "",
      birthdays: "",
      reviewPending: "",
      onLeave: "",
    },
  ],
};

const ProductionDashboard = () => {
  return (
    <Dashboard>
      <FourCardRow>
        <InfoCard
          primaryText="Total Reports YTD"
          secondaryText={"15 Reports"}
        />
        <InfoCard primaryText="Inventory Reports" secondaryText={"5 Reports"} />
        <InfoCard primaryText="Sales Reports" secondaryText={"3 Reports"} />
        <InfoCard primaryText="Employee Reports" secondaryText={"2 Reports"} />
      </FourCardRow>
      <FourGraphs>
        <ContentCard title="Recent Reports">
          <SimpleTable
            tableData={employeeActivityData}
            showNumberColumn={false}
          />
        </ContentCard>
        <ContentCard title="Reports Cetegories" showDateDropdown={true}>
          <CustomBarChart data={customBarChartData} />
        </ContentCard>
        <ContentCard title="Top Requested Reports" showDateDropdown={true}>
          <SimplePieChart data={pieChartData} />
        </ContentCard>
        <ContentCard title="Top Produced Items" showDateDropdown={true}>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" disableElevation>
                Generate Inventory Report
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Generate Sales Report
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Generate Custom Report
              </Button>
            </Grid>
          </Grid>
        </ContentCard>
      </FourGraphs>
    </Dashboard>
  );
};

export default ProductionDashboard;
