import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  useMediaQuery,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import axios from "../../../api";
import DropdownMenu from "../../common/DropdownMenu";
import CrudModalTwo from "../../common/CrudModalTwo";

const InventoryModal = ({ handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    Inventory_Type: "",
  });
  const [isCrudOpen, setIsCrudOpen] = useState(false);
  const [btnModal, setBtnModal] = useState("");
  const [inventoryTypes, setInventoryTypes] = useState([]);

  const handleAddNewClick = () => {
    setFormData({
      Inventory_TypeID: null,
      Inventory_Type: "",
      Image_Location: "",
    });
    setBtnModal("add");
    setIsCrudOpen(true);
  };

  const handleEdit = (item) => {
    console.log("item", item);
    setFormData({
      ...item,
    });
    setBtnModal("update");
    setIsCrudOpen(true);
  };

  const handleDelete = (item) => {
    setFormData({
      ...item,
    });
    setBtnModal("delete");
    setIsCrudOpen(true);
  };

  useEffect(() => {
    axios.get("/inventory_types").then((res) => {
      setInventoryTypes(res.data.data.data);
    });
  }, []);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="inventory-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "700px",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
          overflowY: "auto",
        }}
      >
        <div style={{ position: "relative" }}>
          {isMobile && (
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}

          <h2 className="text-2xl font-semibold mb-2 text-BtnBg">
            Inventory Types
          </h2>
          <div className="border-b border-gray-300 mb-4"></div>
          <button
            type="button"
            className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 cursor-pointer"
            onClick={handleAddNewClick}
          >
            Add New Types
          </button>
          <div className="mt-6 mb-5">
            <input
              type="text"
              placeholder="Type Name"
              className="w-full rounded-2xl text-base font-bold py-5 px-4 bg-[#CCD7E4] placeholder:text-[#143664] text-[#143664] focus:outline-none focus:ring-2 focus:ring-[#143664]"
            />
          </div>

          {inventoryTypes.map((type, index) => (
            <div
              key={index}
              className="bg-[#F5F5F5] rounded-xl py-2 px-5 flex items-center justify-between mb-4"
            >
              <Typography variant="body1" color="textPrimary">
                {type.Inventory_Type}
              </Typography>
              <DropdownMenu
                onEdit={() => handleEdit(type)}
                onDelete={() => handleDelete(type)}
              />
            </div>
          ))}

          <div className="flex justify-end mt-5 gap-3">
            <button
              type="button"
              className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 cursor-pointer"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>

        <CrudModalTwo
          open={isCrudOpen}
          fieldData={formData}
          handleClose={() => setIsCrudOpen(false)}
          btnName={btnModal}
          setData={setInventoryTypes}
        />
      </Box>
    </Modal>
  );
};

export default InventoryModal;
