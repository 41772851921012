import React from "react";
import Barcode from "react-barcode";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const InventoryPrintModal = ({ open, onClose, item }) => {
  const {
    Name,
    Vendor_Name,
    SKU_Number,
    Date_Received,
    Child_Qty,
    Description,
    Inventory_Type,
    Unit_Measure,
  } = item;

  const receivedDateFormatted = new Date(Date_Received)
    .toLocaleDateString("en-US")
    .replace(/\//g, "/");

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent className="relative p-6 group">
        <div
          className="px-6 py-8 bg-white border border-gray-300 mx-auto"
          style={{
            width: "4.1in",
            height: "6.2in",
          }}
        >
          <div className="text-center mb-4">
            <h1 className="text-3xl font-bold mb-4">{Name}</h1>
            <h2 className="text-xl font-bold">{Vendor_Name}</h2>
          </div>

          <div className="text-center mb-4">
            <p className="mb-4">{Description}</p>
            <p className="mb-4 text-2xl capitalize font-bold">{`--${Inventory_Type}--`}</p>
            <p className="mb-2 text-xl capitalize">{`Date Recv: ${receivedDateFormatted}`}</p>
            <p className="mb-2 text-xl capitalize">{`Type: Raw Goods`}</p>
            <p className="mb-2 text-xl capitalize">{`${
              Unit_Measure ? Unit_Measure : "Box"
            }: ${Child_Qty}${
              Unit_Measure?.toLowerCase() !== "flat" ? " LBS" : " Flats"
            }`}</p>
          </div>

          <div className="flex justify-center mt-auto">
            <Barcode value={SKU_Number} width={2} height={60} />
          </div>
        </div>
        <div className="absolute flex justify-center items-center gap-4 opacity-0 invisible group-hover:opacity-100 group-hover:visible w-full h-full top-0 left-0 bg-gray-400 bg-opacity-25 transition-all">
          <IconButton className="w-14 h-14 !bg-gray-400" onClick={onClose}>
            <CloseIcon className="!w-8 !h-8 text-white" />
          </IconButton>
          <IconButton className="w-14 h-14 !bg-green-400" onClick={onClose}>
            <CheckIcon className="!w-8 !h-8 text-white" />
          </IconButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InventoryPrintModal;
