import React from "react";
import Dashboard from "../../../components/common/Layout/Dashboard";
import FourCardRow from "../../../components/common/Layout/FourCardRow";
import FourGraphs from "../../../components/common/Layout/FourGraphs";
import InfoCard from "../../../components/common/InfoCard";
import SimplePieChart from "../../../components/common/Charts/PieChart";
import ContentCard from "../../../components/common/ContentCard";
import MultiLineChart from "../../../components/common/Charts/MultiLineChart";
import SimpleTable from "../../../components/common/Charts/SimpleTableChart";
import suitGroup from "../../../assets/images/suitGroup.svg";
import manInSuit from "../../../assets/images/manInSuit.svg";
import personLeave from "../../../assets/images/personLeave.svg";
import clockCheck from "../../../assets/images/clockCheck.svg";

const pieChartData = {
  unitMeasure: "Employees",
  data: [
    { name: "HR", value: 20 },
    { name: "Productions", value: 50 },
    { name: "Sales", value: 15 },
    { name: "Admins", value: 15 },
  ],
};

const employeeActivityData = {
  columns: [
    { id: "recentlyJoined", label: "Recently Joined" },
    { id: "inTraining", label: "In Training" },
    { id: "birthdays", label: "Birthdays" },
    { id: "reviewPending", label: "Review Pending" },
    { id: "onLeave", label: "On Leave" },
  ],
  rows: [
    {
      id: 1,
      recentlyJoined: "Emma Thompson",
      inTraining: "Michael Chen",
      birthdays: "Sarah Johnson",
      reviewPending: "William Nguyen",
      onLeave: "David Rodriguez",
    },
    {
      id: 2,
      recentlyJoined: "Alexander Lee",
      inTraining: "William Nguyen",
      birthdays: "Olivia Parker",
      reviewPending: "William Nguyen",
      onLeave: "Sara Mitchel",
    },
    {
      id: 3,
      recentlyJoined: "John Doe",
      inTraining: "Sophia Patel",
      birthdays: "-",
      reviewPending: "Bob Mitchel",
      onLeave: "-",
    },
    {
      id: 4,
      recentlyJoined: "Daniel Kim",
      inTraining: "Isabella Martinez",
      birthdays: "-",
      reviewPending: "Larry King",
      onLeave: "-",
    },
  ],
};

const employeeDetailsData = {
  columns: [
    { id: "performance", label: "Performance" },
    { id: "employeeName", label: "Employee Name" },
    { id: "title", label: "Title" },
    { id: "birthday", label: "Birthday" },
  ],
  rows: [
    {
      id: 1,
      performance: "95%",
      employeeName: "Rachel Zhang",
      title: "Senior Developer",
      birthday: "Mar 15",
    },
    {
      id: 2,
      performance: "92%",
      employeeName: "Marcus Johnson",
      title: "Project Manager",
      birthday: "Aug 22",
    },
    {
      id: 3,
      performance: "85%",
      employeeName: "Sofia Rodriguez",
      title: "Marketing Lead",
      birthday: "Jan 30",
    },
    {
      id: 4,
      performance: "94%",
      employeeName: "Thomas Anderson",
      title: "Operations Manager",
      birthday: "Nov 05",
    },
    {
      id: 5,
      performance: "90%",
      employeeName: "Diana Chen",
      title: "Senior Developer",
      birthday: "Jun 17",
    },
  ],
};

const ProductionDashboard = () => {
  return (
    <Dashboard>
      <FourCardRow>
        <InfoCard
          primaryText="Total Employees"
          secondaryText={"100 Employees"}
          icon={suitGroup}
        />
        <InfoCard
          primaryText="Active Employees"
          secondaryText={"85 Employees"}
          icon={manInSuit}
        />
        <InfoCard
          primaryText="On Leave"
          secondaryText={"10 Employees"}
          icon={personLeave}
        />
        <InfoCard
          primaryText="Pending Approvals"
          secondaryText={"5 Employees"}
          icon={clockCheck}
        />
      </FourCardRow>
      <FourGraphs>
        <ContentCard title="Employees by Department">
          <SimplePieChart data={pieChartData} />
        </ContentCard>
        <ContentCard title="Employee Performance Trends">
          <MultiLineChart />
        </ContentCard>
        <ContentCard title="Recent Employee Activities">
          <SimpleTable
            tableData={employeeActivityData}
            showNumberColumn={false}
          />
        </ContentCard>
        <ContentCard title="Top Performing Employees">
          <SimpleTable
            tableData={employeeDetailsData}
            showNumberColumn={false}
          />
        </ContentCard>
      </FourGraphs>
    </Dashboard>
  );
};

export default ProductionDashboard;
