import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Modal, Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import axios from "../../../api";
import ConfirmationModal from "../../modals/ConfirmationModal";

const CrudModal = ({ open, fieldData, handleClose, btnName, setData }) => {
  const [confirmationType, setConfirmationType] = useState(btnName);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [formData, setFormData] = useState(null);

  const dataQuestionSchema = yup.object().shape({
    Inventory_Type: yup.string().required("Inventory Type is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(dataQuestionSchema),
    defaultValues: {
      Inventory_Type: fieldData?.Inventory_Type || "",
    },
    mode: "onChange",
  });

  const onSubmit = useCallback(() => {
    const contactPayload = {
      ...formData,
    };

    const handleError = (err) => {
      console.error(err);
      toast.error(err.response?.data?.errorMessage || "internal server error");
    };

    switch (confirmationType) {
      case "add":
        axios
          .post("/inventory_types", contactPayload)
          .then((res) => {
            setData((prev) => [...prev, res.data.data]);
            toast.success("Product Question Added Successfully");
            reset();
            handleClose();
            setOpenConfirmation(false);
          })
          .catch(handleError);
        break;

      case "update":
        axios
          .put(`/inventory_types/${fieldData?.Inventory_TypeID}`, contactPayload)
          .then((res) => {
            setData((prev) =>
              prev.map((item) =>
                item.Inventory_TypeID === fieldData?.Inventory_TypeID ? res.data.data : item
              )
            );
            toast.success("Product Question Updated Successfully");
            reset();
            handleClose();
            setOpenConfirmation(false);
          })
          .catch(handleError);
        break;

      case "delete":
        axios
          .delete(`/inventory_types/${fieldData?.Inventory_TypeID}`)
          .then((res) => {
            setData((prev) => {
              return prev.filter(
                (item) => item.Inventory_TypeID !== fieldData?.Inventory_TypeID
              );
            });
            toast.success("Product Question Deleted Successfully");
            reset();
            handleClose();
            setOpenConfirmation(false);
          })
          .catch(handleError);
        break;

      case "cancel":
        reset();
        handleClose();
        setOpenConfirmation(false);
        break;

      default:
        console.warn("Unknown button action:", btnName);
    }
  }, [
    btnName,
    confirmationType,
    fieldData,
    formData,
    handleClose,
    reset,
    setData,
  ]);

  const formHandler = useCallback(
    (data) => {
      if (btnName === "add") {
        setConfirmationType("add");
        setFormData(data);
        setOpenConfirmation(true);
      } else if (btnName === "update") {
        setConfirmationType("update");
        setFormData(data);
        setOpenConfirmation(true);
      } else {
        setConfirmationType("delete");
        setOpenConfirmation(true);
      }
    },
    [btnName]
  );

  const handleCancel = useCallback(() => {
    if (isDirty || btnName === "delete") {
      setConfirmationType("cancel");
      setOpenConfirmation(true);
    } else {
      handleClose();
    }
  }, [btnName, handleClose, isDirty]);

  const getButtonClassName = useCallback(() => {
    if (btnName === "delete") {
      return "py-2 px-6 md:px-16 bg-red-600 text-white rounded-xl min-w-36 cursor-pointer capitalize bg-red-400";
    }
    if (btnName === "add") {
      return `py-2 px-6 md:px-16 text-white ${
        !isValid ? "bg-gray-400 text-gray-700 cursor-not-allowed" : "bg-BtnBg"
      } rounded-xl min-w-36 capitalize`;
    }
    if (btnName === "update") {
      return `py-2 px-6 md:px-16 text-white ${
        !isValid || !isDirty
          ? "bg-gray-400 text-gray-700 cursor-not-allowed"
          : "bg-BtnBg"
      } rounded-xl min-w-36 capitalize`;
    }
    return "py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 capitalize";
  }, [btnName, isDirty, isValid]);

  const getButtonStatus = useCallback(() => {
    switch (btnName) {
      case "add":
        return !isValid;
      case "update":
        return !isValid || !isDirty;
      default:
        return false;
    }
  }, [btnName, isDirty, isValid]);

  const isDelete = btnName === "delete";

  useEffect(() => {
    console.log(fieldData);
    if (fieldData) {
      reset({
        Inventory_Type: fieldData.Inventory_Type,
      });
    }
  }, [fieldData, reset]);

  useEffect(() => {
    console.log(btnName);
    setConfirmationType(btnName);
  }, [btnName]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="crud-modal-title">
      <div className="bg-white w-11/12  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  rounded-lg shadow-xl max-w-[600px]">
        <form
          onSubmit={handleSubmit(formHandler)}
          className="small_scroller p-5 md:py-6 md:px-10 max-h-[90vh] overflow-y-auto"
        >
          <Box>
            <div className="flex flex-col">
              <h2
                id="crud-modal-title"
                className="text-xl font-semibold mb-2 text-BtnBg capitalize"
              >
                {btnName} Inventory Type
              </h2>
              <div className="border-b border-gray-300 mb-4"></div>
              <TextField
                id="inventoryType"
                className="col-span-2 !mb-4 bg-[#f4f4f4]"
                label="Inventory Type"
                variant="outlined"
                placeholder="Inventory Type"
                {...register("Inventory_Type")}
                error={!!errors.Inventory_Type}
                helperText={errors.Inventory_Type?.message}
                size="small"
                disabled={isDelete}
              />

              <div className="col-span-3 flex justify-end mt-5 gap-3">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={getButtonClassName()}
                  disabled={getButtonStatus()}
                >
                  {btnName}
                </button>
              </div>
            </div>
          </Box>
        </form>
        <ConfirmationModal
          type={confirmationType}
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          onSubmit={onSubmit}
          from="inventory type"
        />
      </div>
    </Modal>
  );
};

export default CrudModal;
