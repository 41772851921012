import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Modal,
  Box,
  useMediaQuery,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import axios from "../../../api";
import TextInput from "../../common/TextInput";
import ConfirmationModal from "../../modals/ConfirmationModal";
import DollarInput from "../../common/DollarInput";
import AsyncMultiSelect from "../../common/AsyncMultiSelect";
import SearchableField from "../../common/SearchableField";

const InventoryDetailModal = ({
  open,
  handleClose,
  btnValue,
  onSubmit,
  id,
  inventoryID,
}) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const questionRef = useRef(null);

  const [vendor, setVendor] = useState(null);
  const [lotNumID, setLotNumID] = useState(null);
  const [lotNumIDDefault, setLotNumIDDefault] = useState(null);
  const [container, setContainer] = useState("pallete");
  const [quantity, setQuantity] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  const [productQuestions, setProductQuestions] = useState([]);

  const [isUpdated, setIsUpdated] = useState(false);
  const [confirmationType, setConfirmationType] = useState(
    btnValue.toLowerCase()
  );
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [formData, setFormData] = useState(null);

  const inventoryChildSchema = yup.object().shape({
    SKU_Number: yup.string().required("SKU Number is required"),
    Date_Received: yup
      .date()
      .required("Received Date is required")
      .typeError("Invalid date"),
    Unit_Price: yup.number().required("Unit Price is required"),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(inventoryChildSchema),
    defaultValues: {
      SKU_Number: "",
      Date_Received: new Date(),
      Unit_Price: 0,
    },
    mode: "onChange",
  });

  const unitPrice = watch("Unit_Price");

  useEffect(() => {
    const calculateSubTotal = () => {
      setSubTotal((quantity || 0) * (unitPrice || 0));
    };

    calculateSubTotal();
  }, [quantity, unitPrice]);

  const handleModalClose = useCallback(() => {
    reset();
    setVendor(null);
    setLotNumID(null);
    setLotNumIDDefault(null);
    setSubTotal(0);
    handleClose();
  }, [handleClose, reset]);

  const submitHandler = useCallback(() => {
    const handleError = (err) => {
      console.error(err);
      toast.error(err.response?.data?.errorMessage || "internal server error");
    };

    const handleSuccess = (message) => {
      toast.success(message);
      handleModalClose();
    };

    const inventoryChildPayload = {
      VendorID: vendor ? vendor.VendorID : null,
      Vendor: vendor,
      InventoryID: inventoryID,
      SKU_Number: formData ? formData.SKU_Number : "",
      Date_Received: formData ? formData.Date_Received : new Date(),
      Lot_NumID: lotNumID ? lotNumID.Lot_NumID : null,
      Lot_Number: lotNumID,
      Unit_Price: formData ? formData.Unit_Price : "",
      Product_Questions: productQuestions,
    };

    switch (confirmationType) {
      case "add":
        axios
          .post("/inventory-children", inventoryChildPayload)
          .then((res) => {
            onSubmit((prev) => [
              ...prev,
              { ...inventoryChildPayload, ...res.data.data },
            ]);
            handleSuccess("Inventory added Successfully");
            setOpenConfirmation(false);
            handleModalClose();
          })
          .catch(handleError);
        break;

      case "update":
        axios
          .put(`/inventory-children/${id}`, inventoryChildPayload)
          .then((res) => {
            onSubmit((prev) =>
              prev.map((item) =>
                item.Inventory_ChildID === id
                  ? {
                      ...item,
                      ...inventoryChildPayload,
                      ...res.data.data,
                    }
                  : item
              )
            );
            handleSuccess("Inventory updated Successfully");
            setOpenConfirmation(false);
            handleModalClose();
          })
          .catch(handleError);
        break;

      case "delete":
        axios
          .delete(`/inventory-children/${id}`)
          .then((res) => {
            onSubmit((prev) =>
              prev.filter((item) => item.Inventory_ChildID !== id)
            );
            handleSuccess("Inventory Detail Deleted Successfully");
            setOpenConfirmation(false);
            handleModalClose();
          })
          .catch(handleError);
        break;

      case "cancel":
        setOpenConfirmation(false);
        handleModalClose();
        break;

      case "newvendoralt":
        setOpenConfirmation(false);
        navigate("/vendor/manage-vendor-products/new", {
          state: {
            name: null,
            inventoryId: inventoryID,
          },
        });
        break;

      default:
        console.warn("Unknown button action:", btnValue);
    }
  }, [
    btnValue,
    confirmationType,
    formData,
    handleModalClose,
    id,
    inventoryID,
    lotNumID,
    navigate,
    onSubmit,
    productQuestions,
    vendor,
  ]);

  const formHandler = useCallback(
    (data) => {
      switch (confirmationType) {
        case "add":
          setFormData(data);
          setConfirmationType("add");
          setOpenConfirmation(true);
          break;
        case "update":
          setFormData(data);
          setConfirmationType("update");
          setOpenConfirmation(true);
          break;
        case "delete":
          setConfirmationType("delete");
          setOpenConfirmation(true);
          break;
        default:
          break;
      }
    },
    [confirmationType]
  );

  const handleInputChange = (index, value) => {
    let tmp = [...productQuestions];
    tmp[index].Answer = value;
    setProductQuestions(tmp);
    setIsUpdated(true);
  };

  const handleCancel = useCallback(() => {
    if (isDirty || isUpdated || btnValue === "delete") {
      setConfirmationType("cancel");
      setOpenConfirmation(true);
    } else {
      handleClose();
    }
  }, [btnValue, handleClose, isDirty, isUpdated]);

  const isDelete = btnValue === "delete";

  const fetchVendors = async (inputValue) => {
    try {
      const res = await axios.get(
        `/vendors/inventory?inventoryID=${inventoryID}`
      );
      if (res.status === 200) {
        if (res.data.data) {
          return res.data.data.map((item) => ({
            ...item,
            id: item.VendorID,
            name: item.Vendor_Name,
          }));
        }
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const fetchLotNumbers = async (inputValue) => {
    try {
      const res = await axios.get(`/lot-numbers?keyword=${inputValue}`);
      if (res.status === 200) {
        return res.data.data.data;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    setConfirmationType(btnValue.toLowerCase());
  }, [btnValue]);

  useEffect(() => {
    console.log(id);
    if (id) {
      axios
        .get(`/inventory-children/${id}`)
        .then((res) => {
          let info = res.data.data;
          reset({
            SKU_Number: info.SKU_Number,
            Date_Received: new Date(info.Date_Received),
            Unit_Price: info.Unit_Price,
          });
          setVendor({
            ...info.Vendor,
            id: info.Vendor.VendorID,
            name: info.Vendor.Vendor_Name,
          });
          setLotNumID(info.Lot_Number);
          setLotNumIDDefault(info.Lot_Number);
          setProductQuestions(info.Product_Questions);
        })
        .catch((err) => console.error(err));
    }
  }, [id, reset]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="inventory-detail-modal-title"
      >
        <div className="bg-white w-11/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg shadow-xl max-w-[700px] overflow-y-auto">
          <form
            onSubmit={handleSubmit(formHandler)}
            className="small_scroller p-5 md:py-6 md:px-10 max-h-[90vh] overflow-y-auto"
          >
            <Box>
              {isMobile && (
                <IconButton
                  onClick={handleModalClose}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <div className="flex flex-col">
                <h2
                  id="inventory-detail-modal-title"
                  className="text-xl font-semibold mb-2 text-BtnBg"
                >
                  Inventory Details
                </h2>
                <div className="border-b border-gray-300 mb-4"></div>
                <div className="grid grid-cols-3 gap-4 ">
                  <label className="text-gray-700 self-center">
                    Vendor Name<span className="text-red-700">*</span>
                  </label>
                  <SearchableField
                    fetchData={fetchVendors}
                    defaultValue={vendor && vendor.name ? vendor.name : vendor}
                    onfocus={fetchVendors}
                    onChange={(value) => {
                      setIsUpdated(true);
                      setVendor(value);
                      reset({
                        SKU_Number: value?.SKU_Number,
                        Unit_Price: value?.Unit_Price,
                      });
                      setProductQuestions(value?.Product_Questions);
                    }}
                    onBlur={(value) => {
                      if (vendor && typeof vendor === "string") {
                        setConfirmationType("newvendoralt");
                        setOpenConfirmation(true);
                      }
                    }}
                    placeholder="Type to search vendor..."
                    className="col-span-2"
                    disabled={isDelete}
                    error={!vendor}
                  />

                  <label className="text-gray-700 self-center">
                    SKU Number<span className="text-red-700">*</span>
                  </label>
                  <Controller
                    name="SKU_Number"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        className="col-span-2"
                        {...field}
                        placeholder="SKU Number"
                        disabled={isDelete}
                        error={!!errors.SKU_Number}
                      />
                    )}
                  />

                  <label className="text-gray-700 self-center">
                    Date Received<span className="text-red-700">*</span>
                  </label>
                  <Controller
                    name="Date_Received"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        type="date"
                        className="col-span-2"
                        {...field}
                        placeholder="MM/DD/YYYY"
                        disabled={isDelete}
                        error={!!errors.Date_Received}
                      />
                    )}
                  />

                  <label className="text-gray-700 self-center">
                    Lot Number<span className="text-red-700">*</span>
                  </label>
                  <AsyncMultiSelect
                    className="col-span-2"
                    multiple={false}
                    fetchOptions={fetchLotNumbers}
                    displayField="Lot_NumID"
                    defaultValue={lotNumIDDefault}
                    onSelect={(value) => {
                      setIsUpdated(true);
                      setLotNumID(value);
                    }}
                    placeholder="Type to search lot number..."
                    buttonLabel="off"
                    disabled={btnValue !== "add"}
                    error={!lotNumID}
                  />

                  <label className="text-gray-700 self-center">
                    Unit Price<span className="text-red-700">*</span>
                  </label>
                  <Controller
                    name="Unit_Price"
                    control={control}
                    render={({ field }) => (
                      <DollarInput
                        className="col-span-2"
                        {...field}
                        placeholder="$0"
                        disabled={isDelete}
                        error={!!errors.Unit_Price}
                      />
                    )}
                  />

                  <label className="text-gray-700 self-center">
                    Sub Total Price
                  </label>
                  <DollarInput
                    className="col-span-2"
                    placeholder="$0.00"
                    value={parseFloat(subTotal).toFixed(2)}
                    disabled={true}
                  />

                  <h4 className="col-span-3 text-BtnBg font-semibold text-xl">
                    Questions
                  </h4>

                  <div className="flex flex-col col-span-3">
                    {/* {productQuestions &&
                      productQuestions.map((item, index) => (
                        <div
                          key={`product-question-${index}`}
                          className="grid grid-cols-3 items-center mb-4 gap-5"
                        >
                          <p className="pr-4 col-span-1">
                            {item?.Question || ""}
                          </p>
                          <div className="flex col-span-2">
                            <input
                              type="text"
                              className="w-full rounded-lg py-2 px-4 bg-[#f4f4f4] placeholder:text-[#4D5658] text-[#4D5658] focus:outline-none focus:ring-2 focus:ring-BtnBg"
                              placeholder={item?.Value || ""}
                              value={item?.Answer || ""}
                              onChange={(e) => {
                                let tmp = [...productQuestions];
                                tmp[index].Answer = e.target.value;
                                setProductQuestions(tmp);
                                setIsUpdated(true);
                              }}
                              disabled={btnValue === "delete"}
                            />
                          </div>
                        </div>
                      ))} */}
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        bgcolor: "#E2E8F0",
                        borderRadius: 2,
                        py: 2,
                        px: 3,
                        mb: 2,
                        color: "#143664",
                        fontWeight: "bold",
                      }}
                    >
                      <div className="pl-4">Question</div>
                      <div className="pl-4 border-l border-solid border-[#D1D5DB]">
                        Answer
                      </div>
                    </Box>
                    {productQuestions && productQuestions.length > 0 ? (
                      productQuestions.map((question, index) => (
                        <Box
                          key={index}
                          sx={{
                            bgcolor: "#F3F4F6",
                            borderRadius: 2,
                            py: 2,
                            px: 3,
                            mb: 2,
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            alignItems: "center",
                          }}
                        >
                          <div className="text-[#4B5563] pl-4">
                            {question.Question}
                            <span className="text-red-700">*</span>
                            {question.Question === "quantity"
                              ? container === "flat"
                                ? " (flats)"
                                : " (lbs)"
                              : ""}
                          </div>
                          <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                            {question.Type === "float" ? (
                              <input
                                type="number"
                                step="any"
                                className="w-full rounded-lg py-2 px-4 bg-[#f4f4f4] placeholder:text-[#4D5658] border border-[rgba(0,0,0,0.23)] text-[#4D5658] focus:outline-none focus:ring-2 focus:ring-BtnBg"
                                placeholder={
                                  question.Question === "quantity"
                                    ? container === "flat"
                                      ? "flats"
                                      : "lbs"
                                    : question.Value
                                }
                                value={question.Answer || ""}
                                onChange={(e) => {
                                  handleInputChange(index, e.target.value);
                                  if (question.Question === "quantity")
                                    setQuantity(e.target.value);
                                }}
                                disabled={btnValue === "delete"}
                              />
                            ) : question.Type === "boolean" ? (
                              <RadioGroup
                                row
                                value={question.Answer || ""}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="True"
                                  disabled={btnValue === "delete"}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="False"
                                  disabled={btnValue === "delete"}
                                />
                              </RadioGroup>
                            ) : question.Type === "list" ? (
                              <Select
                                value={question.Answer || ""}
                                onChange={(e) => {
                                  handleInputChange(index, e.target.value);
                                  if (question.Question === "container")
                                    setContainer(e.target.value);
                                }}
                                displayEmpty
                                fullWidth
                                disabled={btnValue === "delete"}
                                size="small"
                              >
                                {(question.Value || "")
                                  .split("/")
                                  .map((option, idx) => (
                                    <MenuItem key={idx} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                              </Select>
                            ) : (
                              question.Value
                            )}
                          </div>
                        </Box>
                      ))
                    ) : (
                      <Box
                        sx={{
                          bgcolor: "#F3F4F6",
                          borderRadius: 2,
                          py: 2,
                          px: 3,
                          mb: 3,
                          display: "grid",
                          gridTemplateColumns: "1fr 2fr 2fr 1fr auto",
                          alignItems: "center",
                          gap: 2,
                          whiteSpace: "nowrap",
                        }}
                      >
                        No Questions
                      </Box>
                    )}
                  </div>
                </div>
                <div className="col-span-3 flex justify-end mt-5">
                  <button
                    type="button"
                    className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`py-2 px-6 md:px-16 ml-7 text-white rounded-xl min-w-36 capitalize ${
                      btnValue === "delete"
                        ? "bg-red-500"
                        : btnValue === "add"
                        ? !isValid ||
                          !vendor ||
                          !lotNumID ||
                          productQuestions.filter((item) => !item.Answer)
                            .length > 0
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-BtnBg"
                        : !isValid ||
                          !vendor ||
                          !lotNumID ||
                          productQuestions.filter((item) => !item.Answer)
                            .length > 0 ||
                          (!isDirty && !isUpdated)
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-BtnBg"
                    }`}
                    disabled={
                      btnValue !== "delete"
                        ? btnValue === "add"
                          ? !isValid ||
                            !vendor ||
                            !lotNumID ||
                            productQuestions.filter((item) => !item.Answer)
                              .length > 0
                          : !isValid ||
                            !vendor ||
                            !lotNumID ||
                            productQuestions.filter((item) => !item.Answer)
                              .length > 0 ||
                            (!isDirty && !isUpdated)
                        : false
                    }
                  >
                    {btnValue}
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </div>
      </Modal>
      <ConfirmationModal
        type={confirmationType}
        open={openConfirmation}
        onClose={() => {
          if (confirmationType === "newvendoralt") {
            setOpenConfirmation(false);
            setVendor(null);
          } else {
            setOpenConfirmation(false);
          }
        }}
        onSubmit={submitHandler}
        from="inventory"
      />
    </>
  );
};

export default InventoryDetailModal;
