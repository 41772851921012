import React, { useEffect, useState } from "react";
import Barcode from "react-barcode";
import axios from "../../../api";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Print } from "@mui/icons-material";

const formatNumberWithLeadingZeros = (number) => {
  return number.toString().padStart(8, "0");
};

const InventoryPrintModal = ({ open, onClose, item }) => {
  const {
    Name,
    Storage_Method,
    SKU_Number,
    Date_Received,
    Child_Qty,
    Description,
    Type,
    Lot_NumID,
    Inventory_Type,
    Unit_Measure,
  } = item;
  const [printers, setPrinters] = useState([]);

  const receivedDateFormatted = new Date(Date_Received)
    .toLocaleDateString("en-US")
    .replace(/\//g, "/");

  const onPrint = () => {
    const input = document.getElementById("printable-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [6.2, 4.1],
      });
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save(`${Name}_label.pdf`);
    });
  };

  useEffect(() => {
    axios.get("/printers").then((res) => {
      setPrinters(res.data.data);
    });
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogContent
        id="printable-content"
        className="relative p-2 group"
        style={{
          width: "4.1in",
          height: "6.2in",
          padding: "12px",
        }}
      >
        <div className="flex flex-col pt-8 pb-4 px-4 h-full bg-white border border-gray-300 mx-auto">
          <div className="text-center mb-4">
            <div className="flex justify-center items-center h-16">
              <h1 className="text-3xl font-bold leading-8">{Name}</h1>
            </div>
            {/* <div className="flex justify-center items-center h-8">
              <h2 className="text-xl font-bold leading-8 whitespace-nowrap">
                {Vendor_Name}
              </h2>
            </div> */}
          </div>

          <div className="flex-1 pb-3 text-center border-b-2">
            <div className="flex justify-center items-center h-24 mb-4">
              <p className="leading-6">{Description}</p>
            </div>
            <p className="text-2xl capitalize font-bold">{`--${Type}--`}</p>
            <p className="mb-4 text-2xl capitalize font-bold">
              {Storage_Method}
            </p>
            <div className="text-xl text-center capitalize mb-2">
              Date Recv : {receivedDateFormatted}
            </div>
            <div className="text-xl text-center capitalize mb-2">
              Type : {Inventory_Type}
            </div>
            <div className="text-xl text-center capitalize">
              {Unit_Measure ? Unit_Measure : "Box"} : {Child_Qty}
              {Unit_Measure?.toLowerCase() !== "flat" ? " LBS" : " Flats"}
            </div>
          </div>

          <div className="flex relative justify-center">
            <Barcode value={SKU_Number} width={3} height={120} />
            <div className="absolute right-2 top-1 text-xs">
              Lot-{formatNumberWithLeadingZeros(Lot_NumID)}
            </div>
          </div>
        </div>
        <div className="absolute flex justify-center items-center gap-4 opacity-0 invisible group-hover:opacity-100 group-hover:visible w-full h-full top-0 left-0 bg-gray-400 bg-opacity-25 transition-all">
          <IconButton className="w-14 h-14 !bg-gray-400" onClick={onClose}>
            <CloseIcon className="!w-8 !h-8 text-white" />
          </IconButton>
          <div className="group/buttons flex relative items-center justify-center w-14 h-14 !bg-green-400 rounded-full cursor-pointer">
            <Print className="!w-8 !h-8 text-white" />
            <div className="absolute flex flex-col gap-1 left-full top-1/2 pl-4 -translate-y-1/2 invisible opacity-0 group-hover/buttons:visible group-hover/buttons:opacity-100 transition-all">
              {printers.map((item, index) => (
                <button
                  key={`printer-${index}`}
                  className="p-1 bg-slate-400 w-24 text-sm rounded-md hover:bg-green-400 hover:text-white transition-all"
                >
                  {item.Unit_Name}
                </button>
              ))}
              <button
                className="p-1 bg-slate-400 w-24 text-sm rounded-md hover:bg-green-400 hover:text-white transition-all"
                onClick={onPrint}
              >
                PDF Export
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InventoryPrintModal;
