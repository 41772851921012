import React, { useEffect, useRef, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import trashIcon from "../../../assets/images/trashIcon.svg";
import editIcon from "../../../assets/images/editIcon.svg";
import printerIcon from "../../../assets/images/printerIcon.svg";
import { DROPDOWN_MENU_ICON_STYLE, DROPDOWN_MENU_ITEM_STYLE } from "../utils";

const DropdownMenu = ({
  closeOthers,
  onEdit,
  onDelete,
  onPrint = null,
  last = false,
}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    closeOthers && closeOthers();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <IconButton
        sx={{ color: open ? "#1479FF" : "#272727" }}
        aria-label="more"
        size="small"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      {open && (
        <div
          style={{ zIndex: 999 }}
          ref={menuRef}
          className={`absolute mt-0 bg-white custom-dropdown rounded-xl p-4 flex flex-col items-start gap-2 min-w-40 min-h-24 ${
            last ? "right-full bottom-0" : "right-full top-0"
          }`}
        >
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
              onEdit();
            }}
            className={DROPDOWN_MENU_ITEM_STYLE}
          >
            <span className={DROPDOWN_MENU_ICON_STYLE}>
              <img src={editIcon} className="w-4 h-auto" alt="Edit" />
            </span>
            Edit
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(false);
              onDelete();
            }}
            className={DROPDOWN_MENU_ITEM_STYLE}
          >
            <span className={DROPDOWN_MENU_ICON_STYLE}>
              <img src={trashIcon} className="w-4 h-auto" alt="Delete" />
            </span>
            Delete
          </button>
          {onPrint ? (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(false);
                onDelete();
              }}
              className={DROPDOWN_MENU_ITEM_STYLE}
            >
              <span className={DROPDOWN_MENU_ICON_STYLE}>
                <img src={printerIcon} className="w-5 h-auto" alt="Delete" />
              </span>
              Print
            </button>
          ):""}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
