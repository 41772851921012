import React from "react";
import Dashboard from "../../../components/common/Layout/Dashboard";
import FourCardRow from "../../../components/common/Layout/FourCardRow";
import FourGraphs from "../../../components/common/Layout/FourGraphs";
import InfoCard from "../../../components/common/InfoCard";
import SimplePieChart from "../../../components/common/Charts/PieChart";
import LineBarChart from "../../../components/common/Charts/LineBarChart";
import CustomBarChart from "../../../components/common/Charts/CustomBarChart";
import ContentCard from "../../../components/common/ContentCard";
import HorizontalBarChart from "../../../components/common/Charts/HorizontalBarChart";

const pieChartData = {
  unitMeasure: "",
  data: [
    { name: "VIP Customers", value: 400 },
    { name: "New Customers", value: 300 },
    { name: "Regular Customers", value: 300 },
    { name: "One-Time Customers", value: 200 },
  ],
};

const satisfactionData = {
  unitMeasure: "%",
  data: [
    { name: "Satisfied", value: 80 },
    { name: "Neutral", value: 15 },
    { name: "Dissatisfied", value: 5 },
  ],
};

const lineBarChartData = {
  unitMeasure: "USD",
  data: [
    { day: 1, month: 1, year: 2023, currentValue: 2500, forecastValue: 3000 },
    { day: 2, month: 1, year: 2023, currentValue: 2550, forecastValue: 3050 },
    { day: 3, month: 1, year: 2023, currentValue: 2600, forecastValue: 3100 },
    { day: 4, month: 1, year: 2023, currentValue: 2650, forecastValue: 3150 },
    { day: 5, month: 1, year: 2023, currentValue: 2700, forecastValue: 3200 },
    { day: 6, month: 1, year: 2023, currentValue: 2750, forecastValue: 3250 },
    { day: 7, month: 1, year: 2023, currentValue: 2800, forecastValue: 3300 },
    { day: 8, month: 1, year: 2023, currentValue: 2850, forecastValue: 3350 },
    { day: 9, month: 1, year: 2023, currentValue: 2900, forecastValue: 3400 },
    { day: 10, month: 1, year: 2023, currentValue: 2950, forecastValue: 3450 },
    { day: 11, month: 1, year: 2023, currentValue: 3000, forecastValue: 3500 },
    { day: 12, month: 1, year: 2023, currentValue: 3050, forecastValue: 3550 },
    { day: 13, month: 1, year: 2023, currentValue: 3100, forecastValue: 3600 },
    { day: 14, month: 1, year: 2023, currentValue: 3150, forecastValue: 3650 },
    { day: 15, month: 1, year: 2023, currentValue: 3200, forecastValue: 3700 },
    { day: 16, month: 1, year: 2023, currentValue: 3250, forecastValue: 3750 },
    { day: 17, month: 1, year: 2023, currentValue: 3300, forecastValue: 3800 },
    { day: 18, month: 1, year: 2023, currentValue: 3350, forecastValue: 3850 },
    { day: 19, month: 1, year: 2023, currentValue: 3400, forecastValue: 3900 },
    { day: 20, month: 1, year: 2023, currentValue: 3450, forecastValue: 3950 },
    { day: 21, month: 1, year: 2023, currentValue: 3500, forecastValue: 4000 },
    { day: 22, month: 1, year: 2023, currentValue: 3550, forecastValue: 4050 },
    { day: 23, month: 1, year: 2023, currentValue: 3600, forecastValue: 4100 },
    { day: 24, month: 1, year: 2023, currentValue: 3650, forecastValue: 4150 },
    { day: 25, month: 1, year: 2023, currentValue: 3700, forecastValue: 4200 },
    { day: 26, month: 1, year: 2023, currentValue: 3750, forecastValue: 4250 },
    { day: 27, month: 1, year: 2023, currentValue: 3800, forecastValue: 4300 },
    { day: 28, month: 1, year: 2023, currentValue: 3850, forecastValue: 4350 },
    { day: 29, month: 1, year: 2023, currentValue: 3900, forecastValue: 4400 },
    { day: 30, month: 1, year: 2023, currentValue: 3950, forecastValue: 4450 },
    { day: 31, month: 1, year: 2023, currentValue: 4000, forecastValue: 4500 },
    { day: 1, month: 2, year: 2023, currentValue: 4050, forecastValue: 4550 },
    { day: 2, month: 2, year: 2023, currentValue: 4100, forecastValue: 4600 },
    { day: 3, month: 2, year: 2023, currentValue: 4150, forecastValue: 4650 },
    { day: 4, month: 2, year: 2023, currentValue: 4200, forecastValue: 4700 },
    { day: 5, month: 2, year: 2023, currentValue: 4250, forecastValue: 4750 },
    { day: 6, month: 2, year: 2023, currentValue: 4300, forecastValue: 4800 },
    { day: 7, month: 2, year: 2023, currentValue: 4350, forecastValue: 4850 },
    { day: 8, month: 2, year: 2023, currentValue: 4400, forecastValue: 4900 },
    { day: 9, month: 2, year: 2023, currentValue: 4450, forecastValue: 4950 },
    { day: 10, month: 2, year: 2023, currentValue: 4500, forecastValue: 5000 },
    { day: 11, month: 2, year: 2023, currentValue: 4550, forecastValue: 5050 },
    { day: 12, month: 2, year: 2023, currentValue: 4600, forecastValue: 5100 },
    { day: 13, month: 2, year: 2023, currentValue: 4650, forecastValue: 5150 },
    { day: 14, month: 2, year: 2023, currentValue: 4700, forecastValue: 5200 },
    { day: 15, month: 2, year: 2023, currentValue: 4750, forecastValue: 5250 },
    { day: 16, month: 2, year: 2023, currentValue: 4800, forecastValue: 5300 },
    { day: 17, month: 2, year: 2023, currentValue: 4850, forecastValue: 5350 },
    { day: 18, month: 2, year: 2023, currentValue: 4900, forecastValue: 5400 },
    { day: 19, month: 2, year: 2023, currentValue: 4950, forecastValue: 5450 },
    { day: 20, month: 2, year: 2023, currentValue: 5000, forecastValue: 5500 },
    { day: 21, month: 2, year: 2023, currentValue: 5050, forecastValue: 5550 },
    { day: 22, month: 2, year: 2023, currentValue: 5100, forecastValue: 5600 },
    { day: 23, month: 2, year: 2023, currentValue: 5150, forecastValue: 5650 },
    { day: 24, month: 2, year: 2023, currentValue: 5200, forecastValue: 5700 },
    { day: 25, month: 2, year: 2023, currentValue: 5250, forecastValue: 5750 },
    { day: 26, month: 2, year: 2023, currentValue: 5300, forecastValue: 5800 },
    { day: 27, month: 2, year: 2023, currentValue: 5350, forecastValue: 5850 },
    { day: 28, month: 2, year: 2023, currentValue: 5400, forecastValue: 5900 },
    { day: 1, month: 3, year: 2023, currentValue: 5450, forecastValue: 5950 },
    { day: 2, month: 3, year: 2023, currentValue: 5500, forecastValue: 6000 },
    { day: 3, month: 3, year: 2023, currentValue: 5550, forecastValue: 6050 },
    { day: 4, month: 3, year: 2023, currentValue: 5600, forecastValue: 6100 },
    { day: 5, month: 3, year: 2023, currentValue: 5650, forecastValue: 6150 },
    { day: 6, month: 3, year: 2023, currentValue: 5700, forecastValue: 6200 },
    { day: 7, month: 3, year: 2023, currentValue: 5750, forecastValue: 6250 },
    { day: 8, month: 3, year: 2023, currentValue: 5800, forecastValue: 6300 },
    { day: 9, month: 3, year: 2023, currentValue: 5850, forecastValue: 6350 },
    { day: 10, month: 3, year: 2023, currentValue: 5900, forecastValue: 6400 },
    { day: 11, month: 3, year: 2023, currentValue: 5950, forecastValue: 6450 },
    { day: 12, month: 3, year: 2023, currentValue: 6000, forecastValue: 6500 },
    { day: 13, month: 3, year: 2023, currentValue: 6050, forecastValue: 6550 },
    { day: 14, month: 3, year: 2023, currentValue: 6100, forecastValue: 6600 },
    { day: 15, month: 3, year: 2023, currentValue: 6150, forecastValue: 6650 },
    { day: 16, month: 3, year: 2023, currentValue: 6200, forecastValue: 6700 },
    { day: 17, month: 3, year: 2023, currentValue: 6250, forecastValue: 6750 },
    { day: 18, month: 3, year: 2023, currentValue: 6300, forecastValue: 6800 },
    { day: 19, month: 3, year: 2023, currentValue: 6350, forecastValue: 6850 },
    { day: 20, month: 3, year: 2023, currentValue: 6400, forecastValue: 6900 },
    { day: 21, month: 3, year: 2023, currentValue: 6450, forecastValue: 6950 },
    { day: 22, month: 3, year: 2023, currentValue: 6500, forecastValue: 7000 },
    { day: 23, month: 3, year: 2023, currentValue: 6550, forecastValue: 7050 },
    { day: 24, month: 3, year: 2023, currentValue: 6600, forecastValue: 7100 },
    { day: 25, month: 3, year: 2023, currentValue: 6650, forecastValue: 7150 },
    { day: 26, month: 3, year: 2023, currentValue: 6700, forecastValue: 7200 },
    { day: 27, month: 3, year: 2023, currentValue: 6750, forecastValue: 7250 },
    { day: 28, month: 3, year: 2023, currentValue: 6800, forecastValue: 7300 },
    { day: 29, month: 3, year: 2023, currentValue: 6850, forecastValue: 7350 },
    { day: 30, month: 3, year: 2023, currentValue: 6900, forecastValue: 7400 },
    { day: 31, month: 3, year: 2023, currentValue: 6950, forecastValue: 7450 },
    { day: 1, month: 4, year: 2023, currentValue: 7000, forecastValue: 7500 },
    { day: 2, month: 4, year: 2023, currentValue: 7050, forecastValue: 7550 },
    { day: 3, month: 4, year: 2023, currentValue: 7100, forecastValue: 7600 },
    { day: 4, month: 4, year: 2023, currentValue: 7150, forecastValue: 7650 },
    { day: 5, month: 4, year: 2023, currentValue: 7200, forecastValue: 7700 },
    { day: 6, month: 4, year: 2023, currentValue: 7250, forecastValue: 7750 },
    { day: 7, month: 4, year: 2023, currentValue: 7300, forecastValue: 7800 },
    { day: 8, month: 4, year: 2023, currentValue: 7350, forecastValue: 7850 },
    { day: 9, month: 4, year: 2023, currentValue: 7400, forecastValue: 7900 },
    { day: 10, month: 4, year: 2023, currentValue: 7450, forecastValue: 7950 },
    { day: 11, month: 4, year: 2023, currentValue: 7500, forecastValue: 8000 },
    { day: 12, month: 4, year: 2023, currentValue: 7550, forecastValue: 8050 },
    { day: 13, month: 4, year: 2023, currentValue: 7600, forecastValue: 8100 },
    { day: 14, month: 4, year: 2023, currentValue: 7650, forecastValue: 8150 },
    { day: 15, month: 4, year: 2023, currentValue: 7700, forecastValue: 8200 },
    { day: 16, month: 4, year: 2023, currentValue: 7750, forecastValue: 8250 },
    { day: 17, month: 4, year: 2023, currentValue: 7800, forecastValue: 8300 },
    { day: 18, month: 4, year: 2023, currentValue: 7850, forecastValue: 8350 },
    { day: 19, month: 4, year: 2023, currentValue: 7900, forecastValue: 8400 },
    { day: 20, month: 4, year: 2023, currentValue: 7950, forecastValue: 8450 },
    { day: 21, month: 4, year: 2023, currentValue: 8000, forecastValue: 8500 },
    { day: 22, month: 4, year: 2023, currentValue: 8050, forecastValue: 8550 },
    { day: 23, month: 4, year: 2023, currentValue: 8100, forecastValue: 8600 },
    { day: 24, month: 4, year: 2023, currentValue: 8150, forecastValue: 8650 },
    { day: 25, month: 4, year: 2023, currentValue: 8200, forecastValue: 8700 },
    { day: 26, month: 4, year: 2023, currentValue: 8250, forecastValue: 8750 },
    { day: 27, month: 4, year: 2023, currentValue: 8300, forecastValue: 8800 },
    { day: 28, month: 4, year: 2023, currentValue: 8350, forecastValue: 8850 },
    { day: 29, month: 4, year: 2023, currentValue: 8400, forecastValue: 8900 },
    { day: 30, month: 4, year: 2023, currentValue: 8450, forecastValue: 8950 },
    { day: 1, month: 5, year: 2023, currentValue: 8500, forecastValue: 9000 },
    { day: 2, month: 5, year: 2023, currentValue: 8550, forecastValue: 9050 },
    { day: 3, month: 5, year: 2023, currentValue: 8600, forecastValue: 9100 },
    { day: 4, month: 5, year: 2023, currentValue: 8650, forecastValue: 9150 },
    { day: 5, month: 5, year: 2023, currentValue: 8700, forecastValue: 9200 },
    { day: 6, month: 5, year: 2023, currentValue: 8750, forecastValue: 9250 },
    { day: 7, month: 5, year: 2023, currentValue: 8800, forecastValue: 9300 },
    { day: 8, month: 5, year: 2023, currentValue: 8850, forecastValue: 9350 },
    { day: 9, month: 5, year: 2023, currentValue: 8900, forecastValue: 9400 },
    { day: 10, month: 5, year: 2023, currentValue: 8950, forecastValue: 9450 },
    { day: 11, month: 5, year: 2023, currentValue: 9000, forecastValue: 9500 },
    { day: 12, month: 5, year: 2023, currentValue: 9050, forecastValue: 9550 },
    { day: 13, month: 5, year: 2023, currentValue: 9100, forecastValue: 9600 },
    { day: 14, month: 5, year: 2023, currentValue: 9150, forecastValue: 9650 },
    { day: 15, month: 5, year: 2023, currentValue: 9200, forecastValue: 9700 },
    { day: 16, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 17, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 18, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 19, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 20, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 21, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 22, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 23, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 24, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 25, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 26, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 27, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 28, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 29, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 30, month: 5, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 1, month: 6, year: 2023, currentValue: null, forecastValue: 9000 },
    { day: 2, month: 6, year: 2023, currentValue: null, forecastValue: 9050 },
    { day: 3, month: 6, year: 2023, currentValue: null, forecastValue: 9100 },
    { day: 4, month: 6, year: 2023, currentValue: null, forecastValue: 9150 },
    { day: 5, month: 6, year: 2023, currentValue: null, forecastValue: 9200 },
    { day: 6, month: 6, year: 2023, currentValue: null, forecastValue: 9250 },
    { day: 7, month: 6, year: 2023, currentValue: null, forecastValue: 9300 },
    { day: 8, month: 6, year: 2023, currentValue: null, forecastValue: 9350 },
    { day: 9, month: 6, year: 2023, currentValue: null, forecastValue: 9400 },
    { day: 10, month: 6, year: 2023, currentValue: null, forecastValue: 9450 },
    { day: 11, month: 6, year: 2023, currentValue: null, forecastValue: 9500 },
    { day: 12, month: 6, year: 2023, currentValue: null, forecastValue: 9550 },
    { day: 13, month: 6, year: 2023, currentValue: null, forecastValue: 9600 },
    { day: 14, month: 6, year: 2023, currentValue: null, forecastValue: 9650 },
    { day: 15, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 16, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 17, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 18, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 19, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 20, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 21, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 22, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 23, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 24, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 25, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 26, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 27, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 28, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 29, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
    { day: 30, month: 6, year: 2023, currentValue: null, forecastValue: 9700 },
  ],
};

const barChartData = {
  unitMeasure: "LBS",
  data: [
    {
      type: "added",
      data: [
        { year: "2022", month: "Jan", value: 100 },
        { year: "2022", month: "Feb", value: 120 },
        { year: "2022", month: "Mar", value: 110 },
        { year: "2022", month: "Apr", value: 130 },
        { year: "2022", month: "May", value: 140 },
        { year: "2022", month: "Jun", value: 150 },
        { year: "2022", month: "Jul", value: 160 },
        { year: "2022", month: "Aug", value: 170 },
        { year: "2022", month: "Sep", value: 180 },
        { year: "2022", month: "Oct", value: 190 },
        { year: "2022", month: "Nov", value: 200 },
        { year: "2022", month: "Dec", value: 210 },
        { year: "2023", month: "Jan", value: 120 },
        { year: "2023", month: "Feb", value: 130 },
        { year: "2023", month: "Mar", value: 140 },
        { year: "2023", month: "Apr", value: 150 },
        { year: "2023", month: "May", value: 160 },
        { year: "2023", month: "Jun", value: 170 },
        { year: "2023", month: "Jul", value: 180 },
        { year: "2023", month: "Aug", value: 190 },
        { year: "2023", month: "Sep", value: 200 },
        { year: "2023", month: "Oct", value: 210 },
        { year: "2023", month: "Nov", value: 220 },
        { year: "2023", month: "Dec", value: 230 },
      ],
    },
    {
      type: "production",
      data: [
        { year: "2022", month: "Jan", value: 200 },
        { year: "2022", month: "Feb", value: 220 },
        { year: "2022", month: "Mar", value: 210 },
        { year: "2022", month: "Apr", value: 230 },
        { year: "2022", month: "May", value: 240 },
        { year: "2022", month: "Jun", value: 250 },
        { year: "2022", month: "Jul", value: 260 },
        { year: "2022", month: "Aug", value: 270 },
        { year: "2022", month: "Sep", value: 280 },
        { year: "2022", month: "Oct", value: 290 },
        { year: "2022", month: "Nov", value: 300 },
        { year: "2022", month: "Dec", value: 310 },
        { year: "2023", month: "Jan", value: 220 },
        { year: "2023", month: "Feb", value: 230 },
        { year: "2023", month: "Mar", value: 240 },
        { year: "2023", month: "Apr", value: 250 },
        { year: "2023", month: "May", value: 260 },
        { year: "2023", month: "Jun", value: 270 },
        { year: "2023", month: "Jul", value: 280 },
        { year: "2023", month: "Aug", value: 290 },
        { year: "2023", month: "Sep", value: 300 },
        { year: "2023", month: "Oct", value: 310 },
        { year: "2023", month: "Nov", value: 320 },
        { year: "2023", month: "Dec", value: 330 },
      ],
    },
    {
      type: "adjusted",
      data: [
        { year: "2022", month: "Jan", value: 50 },
        { year: "2022", month: "Feb", value: 60 },
        { year: "2022", month: "Mar", value: 55 },
        { year: "2022", month: "Apr", value: 65 },
        { year: "2022", month: "May", value: 70 },
        { year: "2022", month: "Jun", value: 75 },
        { year: "2022", month: "Jul", value: 80 },
        { year: "2022", month: "Aug", value: 85 },
        { year: "2022", month: "Sep", value: 90 },
        { year: "2022", month: "Oct", value: 95 },
        { year: "2022", month: "Nov", value: 100 },
        { year: "2022", month: "Dec", value: 105 },
        { year: "2023", month: "Jan", value: 60 },
        { year: "2023", month: "Feb", value: 65 },
        { year: "2023", month: "Mar", value: 70 },
        { year: "2023", month: "Apr", value: 75 },
        { year: "2023", month: "May", value: 80 },
        { year: "2023", month: "Jun", value: 85 },
        { year: "2023", month: "Jul", value: 90 },
        { year: "2023", month: "Aug", value: 95 },
        { year: "2023", month: "Sep", value: 100 },
        { year: "2023", month: "Oct", value: 105 },
        { year: "2023", month: "Nov", value: 110 },
        { year: "2023", month: "Dec", value: 115 },
      ],
    },
    {
      type: "rejected",
      data: [
        { year: "2022", month: "Jan", value: 10 },
        { year: "2022", month: "Feb", value: 15 },
        { year: "2022", month: "Mar", value: 12 },
        { year: "2022", month: "Apr", value: 18 },
        { year: "2022", month: "May", value: 20 },
        { year: "2022", month: "Jun", value: 22 },
        { year: "2022", month: "Jul", value: 25 },
        { year: "2022", month: "Aug", value: 28 },
        { year: "2022", month: "Sep", value: 30 },
        { year: "2022", month: "Oct", value: 32 },
        { year: "2022", month: "Nov", value: 35 },
        { year: "2022", month: "Dec", value: 38 },
        { year: "2023", month: "Jan", value: 15 },
        { year: "2023", month: "Feb", value: 18 },
        { year: "2023", month: "Mar", value: 20 },
        { year: "2023", month: "Apr", value: 22 },
        { year: "2023", month: "May", value: 25 },
        { year: "2023", month: "Jun", value: 28 },
        { year: "2023", month: "Jul", value: 30 },
        { year: "2023", month: "Aug", value: 32 },
        { year: "2023", month: "Sep", value: 35 },
        { year: "2023", month: "Oct", value: 38 },
        { year: "2023", month: "Nov", value: 40 },
        { year: "2023", month: "Dec", value: 42 },
      ],
    },
  ],
};

const CustomerDashboard = () => {
  return (
    <Dashboard>
      <FourCardRow>
        <InfoCard primaryText="Total Customers" secondaryText={"3,150"} />
        <InfoCard primaryText="Active Customers" secondaryText={"2,225"} />
        <InfoCard primaryText="Inactive Customers" secondaryText={"110"} />
        <InfoCard primaryText="New Customers YTD" secondaryText={"15"} />
      </FourCardRow>
      <FourGraphs>
        <ContentCard title="Customer Segmentation">
          <SimplePieChart data={pieChartData} />
        </ContentCard>
        <ContentCard title="Customer Sales & Forecast" showDateDropdown={true}>
          <LineBarChart
            data={lineBarChartData}
            lineName={"Forecast Sales"}
            barName={"Total Sales"}
          />
        </ContentCard>
        <ContentCard
          title="Customer Satisfaction Overview"
          showDateDropdown={false}
        >
          <SimplePieChart data={satisfactionData} />
        </ContentCard>
        <ContentCard title="Top 5 Customers by Revenue" showDateDropdown={true}>
          <HorizontalBarChart label={"000$"} />
        </ContentCard>
      </FourGraphs>
    </Dashboard>
  );
};

export default CustomerDashboard;
