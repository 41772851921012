import React from "react";
import Dashboard from "../../../components/common/Layout/Dashboard";
import FourCardRow from "../../../components/common/Layout/FourCardRow";
import FourGraphs from "../../../components/common/Layout/FourGraphs";
import InfoCard from "../../../components/common/InfoCard";
import SimplePieChart from "../../../components/common/Charts/PieChart";
import LineBarChart from "../../../components/common/Charts/LineBarChart";
import CustomBarChart from "../../../components/common/Charts/CustomBarChart";
import ContentCard from "../../../components/common/ContentCard";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";

const pieChartData = {
  unitMeasure: "LBS",
  data: [
    { name: "Raw Goods", value: 8000 },
    { name: "Packaging", value: 4500 },
    { name: "Cleaning Supplies", value: 12000 },
    { name: "Miscellaneous", value: 4500 },
  ],
};

const lineBarChartData = {
  unitMeasure: "LBS",
  data: [
    // 2023 Data
    {
      name: "2023-Jan",
      year: "2023",
      quarter: "Q1",
      barValue: 2500,
      lineValue: 3000,
    },
    {
      name: "2023-Feb",
      year: "2023",
      quarter: "Q1",
      barValue: 5100,
      lineValue: 5800,
    },
    {
      name: "2023-Mar",
      year: "2023",
      quarter: "Q1",
      barValue: 4200,
      lineValue: 4900,
    },
    {
      name: "2023-Apr",
      year: "2023",
      quarter: "Q2",
      barValue: 6900,
      lineValue: 8100,
    },
    {
      name: "2023-May",
      year: "2023",
      quarter: "Q2",
      barValue: 4000,
      lineValue: 5200,
    },
    {
      name: "2023-Jun",
      year: "2023",
      quarter: "Q2",
      barValue: 8000,
      lineValue: 6200,
    },
    {
      name: "2023-Jul",
      year: "2023",
      quarter: "Q3",
      barValue: 7500,
      lineValue: 7700,
    },
    {
      name: "2023-Aug",
      year: "2023",
      quarter: "Q3",
      barValue: 6500,
      lineValue: 6700,
    },
    {
      name: "2023-Sep",
      year: "2023",
      quarter: "Q3",
      barValue: 5500,
      lineValue: 5700,
    },
    {
      name: "2023-Oct",
      year: "2023",
      quarter: "Q4",
      barValue: 4500,
      lineValue: 4700,
    },
    {
      name: "2023-Nov",
      year: "2023",
      quarter: "Q4",
      barValue: 3500,
      lineValue: 3700,
    },
    {
      name: "2023-Dec",
      year: "2023",
      quarter: "Q4",
      barValue: 2500,
      lineValue: 2700,
    },

    // 2022 Data
    {
      name: "2022-Jan",
      year: "2022",
      quarter: "Q1",
      barValue: 2000,
      lineValue: 2500,
    },
    {
      name: "2022-Feb",
      year: "2022",
      quarter: "Q1",
      barValue: 4800,
      lineValue: 5500,
    },
    {
      name: "2022-Mar",
      year: "2022",
      quarter: "Q1",
      barValue: 3900,
      lineValue: 4600,
    },
    {
      name: "2022-Apr",
      year: "2022",
      quarter: "Q2",
      barValue: 6600,
      lineValue: 7800,
    },
    {
      name: "2022-May",
      year: "2022",
      quarter: "Q2",
      barValue: 3700,
      lineValue: 4900,
    },
    {
      name: "2022-Jun",
      year: "2022",
      quarter: "Q2",
      barValue: 7700,
      lineValue: 5900,
    },
    {
      name: "2022-Jul",
      year: "2022",
      quarter: "Q3",
      barValue: 7200,
      lineValue: 7400,
    },
    {
      name: "2022-Aug",
      year: "2022",
      quarter: "Q3",
      barValue: 6200,
      lineValue: 6400,
    },
    {
      name: "2022-Sep",
      year: "2022",
      quarter: "Q3",
      barValue: 5200,
      lineValue: 5400,
    },
    {
      name: "2022-Oct",
      year: "2022",
      quarter: "Q4",
      barValue: 4200,
      lineValue: 4400,
    },
    {
      name: "2022-Nov",
      year: "2022",
      quarter: "Q4",
      barValue: 3200,
      lineValue: 3400,
    },
    {
      name: "2022-Dec",
      year: "2022",
      quarter: "Q4",
      barValue: 2200,
      lineValue: 2400,
    },

    // 2021 Data
    {
      name: "2021-Jan",
      year: "2021",
      quarter: "Q1",
      barValue: 1500,
      lineValue: 2000,
    },
    {
      name: "2021-Feb",
      year: "2021",
      quarter: "Q1",
      barValue: 4500,
      lineValue: 5200,
    },
    {
      name: "2021-Mar",
      year: "2021",
      quarter: "Q1",
      barValue: 3600,
      lineValue: 4300,
    },
    {
      name: "2021-Apr",
      year: "2021",
      quarter: "Q2",
      barValue: 6300,
      lineValue: 7500,
    },
    {
      name: "2021-May",
      year: "2021",
      quarter: "Q2",
      barValue: 3400,
      lineValue: 4600,
    },
    {
      name: "2021-Jun",
      year: "2021",
      quarter: "Q2",
      barValue: 7400,
      lineValue: 5600,
    },
    {
      name: "2021-Jul",
      year: "2021",
      quarter: "Q3",
      barValue: 6900,
      lineValue: 7100,
    },
    {
      name: "2021-Aug",
      year: "2021",
      quarter: "Q3",
      barValue: 5900,
      lineValue: 6100,
    },
    {
      name: "2021-Sep",
      year: "2021",
      quarter: "Q3",
      barValue: 4900,
      lineValue: 5100,
    },
    {
      name: "2021-Oct",
      year: "2021",
      quarter: "Q4",
      barValue: 3900,
      lineValue: 4100,
    },
    {
      name: "2021-Nov",
      year: "2021",
      quarter: "Q4",
      barValue: 2900,
      lineValue: 3100,
    },
    {
      name: "2021-Dec",
      year: "2021",
      quarter: "Q4",
      barValue: 1900,
      lineValue: 2100,
    },
  ],
};

const barChartData = {
  unitMeasure: "LBS",
  data: [
    {
      type: "added",
      data: [
        { year: "2022", month: "Jan", value: 100 },
        { year: "2022", month: "Feb", value: 120 },
        { year: "2022", month: "Mar", value: 110 },
        { year: "2022", month: "Apr", value: 130 },
        { year: "2022", month: "May", value: 140 },
        { year: "2022", month: "Jun", value: 150 },
        { year: "2022", month: "Jul", value: 160 },
        { year: "2022", month: "Aug", value: 170 },
        { year: "2022", month: "Sep", value: 180 },
        { year: "2022", month: "Oct", value: 190 },
        { year: "2022", month: "Nov", value: 200 },
        { year: "2022", month: "Dec", value: 210 },
        { year: "2023", month: "Jan", value: 120 },
        { year: "2023", month: "Feb", value: 130 },
        { year: "2023", month: "Mar", value: 140 },
        { year: "2023", month: "Apr", value: 150 },
        { year: "2023", month: "May", value: 160 },
        { year: "2023", month: "Jun", value: 170 },
        { year: "2023", month: "Jul", value: 180 },
        { year: "2023", month: "Aug", value: 190 },
        { year: "2023", month: "Sep", value: 200 },
        { year: "2023", month: "Oct", value: 210 },
        { year: "2023", month: "Nov", value: 220 },
        { year: "2023", month: "Dec", value: 230 },
      ],
    },
    {
      type: "production",
      data: [
        { year: "2022", month: "Jan", value: 200 },
        { year: "2022", month: "Feb", value: 220 },
        { year: "2022", month: "Mar", value: 210 },
        { year: "2022", month: "Apr", value: 230 },
        { year: "2022", month: "May", value: 240 },
        { year: "2022", month: "Jun", value: 250 },
        { year: "2022", month: "Jul", value: 260 },
        { year: "2022", month: "Aug", value: 270 },
        { year: "2022", month: "Sep", value: 280 },
        { year: "2022", month: "Oct", value: 290 },
        { year: "2022", month: "Nov", value: 300 },
        { year: "2022", month: "Dec", value: 310 },
        { year: "2023", month: "Jan", value: 220 },
        { year: "2023", month: "Feb", value: 230 },
        { year: "2023", month: "Mar", value: 240 },
        { year: "2023", month: "Apr", value: 250 },
        { year: "2023", month: "May", value: 260 },
        { year: "2023", month: "Jun", value: 270 },
        { year: "2023", month: "Jul", value: 280 },
        { year: "2023", month: "Aug", value: 290 },
        { year: "2023", month: "Sep", value: 300 },
        { year: "2023", month: "Oct", value: 310 },
        { year: "2023", month: "Nov", value: 320 },
        { year: "2023", month: "Dec", value: 330 },
      ],
    },
    {
      type: "adjusted",
      data: [
        { year: "2022", month: "Jan", value: 50 },
        { year: "2022", month: "Feb", value: 60 },
        { year: "2022", month: "Mar", value: 55 },
        { year: "2022", month: "Apr", value: 65 },
        { year: "2022", month: "May", value: 70 },
        { year: "2022", month: "Jun", value: 75 },
        { year: "2022", month: "Jul", value: 80 },
        { year: "2022", month: "Aug", value: 85 },
        { year: "2022", month: "Sep", value: 90 },
        { year: "2022", month: "Oct", value: 95 },
        { year: "2022", month: "Nov", value: 100 },
        { year: "2022", month: "Dec", value: 105 },
        { year: "2023", month: "Jan", value: 60 },
        { year: "2023", month: "Feb", value: 65 },
        { year: "2023", month: "Mar", value: 70 },
        { year: "2023", month: "Apr", value: 75 },
        { year: "2023", month: "May", value: 80 },
        { year: "2023", month: "Jun", value: 85 },
        { year: "2023", month: "Jul", value: 90 },
        { year: "2023", month: "Aug", value: 95 },
        { year: "2023", month: "Sep", value: 100 },
        { year: "2023", month: "Oct", value: 105 },
        { year: "2023", month: "Nov", value: 110 },
        { year: "2023", month: "Dec", value: 115 },
      ],
    },
    {
      type: "rejected",
      data: [
        { year: "2022", month: "Jan", value: 10 },
        { year: "2022", month: "Feb", value: 15 },
        { year: "2022", month: "Mar", value: 12 },
        { year: "2022", month: "Apr", value: 18 },
        { year: "2022", month: "May", value: 20 },
        { year: "2022", month: "Jun", value: 22 },
        { year: "2022", month: "Jul", value: 25 },
        { year: "2022", month: "Aug", value: 28 },
        { year: "2022", month: "Sep", value: 30 },
        { year: "2022", month: "Oct", value: 32 },
        { year: "2022", month: "Nov", value: 35 },
        { year: "2022", month: "Dec", value: 38 },
        { year: "2023", month: "Jan", value: 15 },
        { year: "2023", month: "Feb", value: 18 },
        { year: "2023", month: "Mar", value: 20 },
        { year: "2023", month: "Apr", value: 22 },
        { year: "2023", month: "May", value: 25 },
        { year: "2023", month: "Jun", value: 28 },
        { year: "2023", month: "Jul", value: 30 },
        { year: "2023", month: "Aug", value: 32 },
        { year: "2023", month: "Sep", value: 35 },
        { year: "2023", month: "Oct", value: 38 },
        { year: "2023", month: "Nov", value: 40 },
        { year: "2023", month: "Dec", value: 42 },
      ],
    },
  ],
};

const ProductionDashboard = () => {
  return (
    <Dashboard>
      <FourCardRow>
        <InfoCard primaryText="User Management" secondaryText={"50 Users"} />
        <InfoCard
          primaryText="Roles & Permissions"
          secondaryText={"10 Roles"}
        />
        <InfoCard
          primaryText="System Configuration"
          secondaryText={"Last Updated: 2 Days Ago"}
        />
        <InfoCard primaryText="Audit Logs" secondaryText={"100 Entries"} />
      </FourCardRow>
      <FourGraphs>
        <ContentCard title="Top Produced Items">
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" disableElevation>
                Add/Remove User
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Assign Roles
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Manage Permission
              </Button>
            </Grid>
          </Grid>
        </ContentCard>
        <ContentCard title="System Configuration">
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" disableElevation>
                Update System Settings
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Backup & Restore
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                API Management
              </Button>
            </Grid>
          </Grid>
        </ContentCard>
        <ContentCard title="Roles & Permissions" showDateDropdown={true}>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" disableElevation>
                Create/Modify Roles
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Assign Permission to Roles
              </Button>
            </Grid>
          </Grid>
        </ContentCard>
        <ContentCard title="Audit Logs" showDateDropdown={true}>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" disableElevation>
                View Recent Activity
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" disableElevation>
                Download Logs
              </Button>
            </Grid>
          </Grid>
        </ContentCard>
      </FourGraphs>
    </Dashboard>
  );
};

export default ProductionDashboard;
