import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@mui/material/styles";

const data = [
  { name: "Joe", orders: 7 },
  { name: "John Macy", orders: 5 },
  { name: "Greg", orders: 8 },
  { name: "Stacy", orders: 4 },
  { name: "Kyle", orders: 6 },
];

const HorizontalBarChart = ({ label }) => {
  const theme = useTheme();

  const valueFormatter = (value) => `${value} ${label}`;

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={[0, 10]}
          ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          tickFormatter={valueFormatter}
          label={{ value: "Orders", position: "bottom" }}
          tick={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 500,
            fontSize: "14px",
            fill: "#272727",
          }}
        />
        <YAxis
          dataKey="name"
          type="category"
          width={100}
          tick={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 500,
            fontSize: "14px",
            fill: "#272727",
          }}
        />
        <Tooltip />
        <Bar
          dataKey="orders"
          fill={theme.palette.tertiary.main}
          radius={[0, 5, 5, 0]}
          barSize={20}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChart;
