import React, { useEffect, useState } from "react";

import { Box, Modal } from "@mui/material";

import axios from "../../../api";
import DropdownMenu from "../../common/DropdownMenu";
import CrudModal from "../../common/CrudModal";
import ConfirmationModal from "../ConfirmationModal";

const ProductQuestionsModal = ({
  data = [],
  setData = null,
  isDelete = false,
  disabled = false,
  isModal = false,
  isModalOpen = false,
  setIsModalOpen = null,
}) => {
  const [formData, setFormData] = useState({
    Question: "",
    Value: "",
    Type: "",
  });

  const [productQuestions, setProductQuestions] = useState([]);
  const [isCrudOpen, setIsCrudOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [btnModal, setBtnModal] = useState("add");

  const handleAddNewQuestionClick = () => {
    setFormData({
      Question: "",
      Value: "",
      Type: "",
    });
    setBtnModal("add");
    setIsCrudOpen(true);
  };

  const handleCloseAddQuestionModal = () => {
    setIsCrudOpen(false);
  };

  const onEdit = (question, idx) => {
    setFormData({ ...question, index: idx });
    setBtnModal("update");
    setIsCrudOpen(true);
  };

  const onDelete = (question, idx) => {
    setFormData({ ...question, index: idx });
    setBtnModal("delete");
    setIsCrudOpen(true);
  };

  useEffect(() => {
    axios.get("/product-questions").then((res) => {
      setProductQuestions(res.data.data);
    });
  }, []);

  return (
    <>
      {isModal ? (
        isModalOpen && (
          <Modal
            open={true}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="product-questions-modal-title"
            className="flex items-center justify-center"
          >
            <Box className="bg-white w-11/12 rounded-lg shadow-xl max-w-2xl overflow-y-auto p-5 md:px-11 md:py-6">
              <Box
                sx={{
                  bgcolor: "#ffffff",
                  borderRadius: 2,
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <h2 className="text-2xl font-semibold mb-2 text-BtnBg">
                    Product Questions
                  </h2>
                  <div
                    style={{
                      borderBottom: "1px solid #D1D5DB",
                      marginBottom: "1rem",
                    }}
                  ></div>
                  <button
                    type="button"
                    className={`py-2 px-6 md:px-16  text-white rounded-xl min-w-36 ${
                      disabled
                        ? "cursor-not-allowed bg-gray-400"
                        : "cursor-pointer bg-BtnBg"
                    }`}
                    onClick={handleAddNewQuestionClick}
                    disabled={disabled}
                  >
                    Add New Question
                  </button>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 3fr 3fr 2fr 1fr",
                    bgcolor: "#E2E8F0",
                    borderRadius: 2,
                    py: 2,
                    px: 3,
                    mb: 2,
                    color: "#143664",
                    fontWeight: "bold",
                  }}
                >
                  {isModal ? <div></div> : <div>Select</div>}
                  <div
                    className={`pl-4 border-l border-solid ${
                      isModal ? "border-transparent" : "border-[#D1D5DB]"
                    }`}
                  >
                    Question
                  </div>
                  <div className="pl-4 border-l border-solid border-[#D1D5DB]">
                    Value
                  </div>
                  <div className="pl-4 border-l border-solid border-[#D1D5DB]">
                    Unit
                  </div>
                  <div></div>
                </Box>
                {productQuestions && productQuestions.length > 0 ? (
                  productQuestions.map((question, index) => (
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "#F3F4F6",
                        borderRadius: 2,
                        py: 2,
                        px: 3,
                        mb: 2,
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr 3fr 2fr 1fr",
                        alignItems: "center",
                      }}
                    >
                      {isModal ? (
                        <div className="relative flex justify-center items-center w-5 h-5">
                          {index + 1}
                        </div>
                      ) : (
                        <div className="relative flex items-center w-5 h-5">
                          <span
                            className={`absolute top-0 left-0 border-2 border-solid w-5 h-5 rounded transition-all ${
                              data.includes(question["ProductQID"])
                                ? " border-BtnBg"
                                : " border-[#D1D5DB]"
                            }`}
                          ></span>
                          <span
                            className={`absolute w-2 h-2 bg-BtnBg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full transition-all ${
                              data.includes(question["ProductQID"])
                                ? "opacity-100 visible"
                                : "opacity-0 invisible"
                            }`}
                          ></span>
                          <input
                            type="checkbox"
                            className="w-5 h-5 bg-transparent opacity-0"
                            onChange={(e) => {
                              if (data.includes(question["ProductQID"])) {
                                setData(
                                  data.filter(
                                    (item) => item !== question["ProductQID"]
                                  )
                                );
                              } else {
                                setData(
                                  Array.from(
                                    new Set([...data, question["ProductQID"]])
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                      )}

                      <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                        {question.Question}
                      </div>
                      <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                        {question.Value}&nbsp;
                      </div>
                      <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                        {question.Type}
                      </div>
                      {!isDelete && !disabled && (
                        <DropdownMenu
                          onEdit={() => onEdit(question, index)}
                          onDelete={() => onDelete(question, index)}
                          last={index + 1 === productQuestions.length}
                        />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      bgcolor: "#F3F4F6",
                      borderRadius: 2,
                      py: 2,
                      px: 3,
                      mb: 3,
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr 2fr 1fr auto",
                      alignItems: "center",
                      gap: 2,
                      whiteSpace: "nowrap",
                    }}
                  >
                    No Questions Added
                  </Box>
                )}

                {isModal && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 3,
                      gap: 3,
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 cursor-pointer"
                    >
                      Cancel
                    </button>
                  </Box>
                )}
              </Box>
            </Box>
          </Modal>
        )
      ) : (
        <Box className="bg-white w-11/12 rounded-lg shadow-xl max-w-2xl overflow-y-auto p-5 md:px-11 md:py-6">
          <Box
            sx={{
              bgcolor: "#ffffff",
              borderRadius: 2,
              maxHeight: "90vh",
              overflowY: "auto",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <h2 className="text-2xl font-semibold mb-2 text-BtnBg">
                Product Questions
              </h2>
              <div
                style={{
                  borderBottom: "1px solid #D1D5DB",
                  marginBottom: "1rem",
                }}
              ></div>
              <button
                type="button"
                className={`py-2 px-6 md:px-16  text-white rounded-xl min-w-36 ${
                  disabled
                    ? "cursor-not-allowed bg-gray-400"
                    : "cursor-pointer bg-BtnBg"
                }`}
                onClick={handleAddNewQuestionClick}
                disabled={disabled}
              >
                Add New Question
              </button>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr 3fr 2fr 1fr",
                bgcolor: "#E2E8F0",
                borderRadius: 2,
                py: 2,
                px: 3,
                mb: 2,
                color: "#143664",
                fontWeight: "bold",
              }}
            >
              <div>Select</div>
              <div className="pl-4 border-l border-solid border-[#D1D5DB]">
                Question
              </div>
              <div className="pl-4 border-l border-solid border-[#D1D5DB]">
                Value
              </div>
              <div className="pl-4 border-l border-solid border-[#D1D5DB]">
                Unit
              </div>
              <div></div>
            </Box>
            {productQuestions && productQuestions.length > 0 ? (
              productQuestions.map((question, index) => (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#F3F4F6",
                    borderRadius: 2,
                    py: 2,
                    px: 3,
                    mb: 2,
                    display: "grid",
                    gridTemplateColumns: "1fr 3fr 3fr 2fr 1fr",
                    alignItems: "center",
                  }}
                >
                  <div className="relative flex items-center w-5 h-5">
                    <span
                      className={`absolute top-0 left-0 border-2 border-solid w-5 h-5 rounded transition-all ${
                        data.includes(question["ProductQID"])
                          ? " border-BtnBg"
                          : " border-[#D1D5DB]"
                      }`}
                    ></span>
                    <span
                      className={`absolute w-2 h-2 bg-BtnBg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full transition-all ${
                        data.includes(question["ProductQID"])
                          ? "opacity-100 visible"
                          : "opacity-0 invisible"
                      }`}
                    ></span>
                    <input
                      type="checkbox"
                      className="w-5 h-5 bg-transparent opacity-0"
                      onChange={(e) => {
                        if (data.includes(question["ProductQID"])) {
                          setData(
                            data.filter(
                              (item) => item !== question["ProductQID"]
                            )
                          );
                        } else {
                          setData(
                            Array.from(
                              new Set([...data, question["ProductQID"]])
                            )
                          );
                        }
                      }}
                    />
                  </div>

                  <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                    {question.Question}
                  </div>
                  <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                    {question.Value}&nbsp;
                  </div>
                  <div className="text-[#4B5563] border-l border-solid border-[#D1D5DB] pl-4">
                    {question.Type}
                  </div>
                  {!isDelete && !disabled && (
                    <DropdownMenu
                      onEdit={() => onEdit(question, index)}
                      onDelete={() => onDelete(question, index)}
                      last={index + 1 === productQuestions.length}
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  bgcolor: "#F3F4F6",
                  borderRadius: 2,
                  py: 2,
                  px: 3,
                  mb: 3,
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr 2fr 1fr auto",
                  alignItems: "center",
                  gap: 2,
                  whiteSpace: "nowrap",
                }}
              >
                No Questions Added
              </Box>
            )}

            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: 3,
            }}
          >
            <button
              type="button"
              onClick={handleCancel}
              className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 cursor-pointer"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleUpdate}
              className={`py-2 px-6 md:px-16  text-white rounded-xl min-w-36 ${
                isUpdated ? "bg-BtnBg" : "bg-gray-400 cursor-not-allowed"
              }`}
              disabled={!isUpdated}
            >
              Update
            </button>
        </Box> */}
          </Box>
        </Box>
      )}

      {isCrudOpen && (
        <CrudModal
          open={true}
          fieldData={formData}
          handleClose={handleCloseAddQuestionModal}
          btnName={btnModal}
          setProductQuestions={setProductQuestions}
        />
      )}
      <ConfirmationModal
        type="cancel"
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onSubmit={() => {
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default ProductQuestionsModal;
