import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../../api";
import ConfirmationModal from "../ConfirmationModal";

const CrudModalThree = ({ open, fieldData, handleClose, btnName, setData }) => {
  const [confirmationType, setConfirmationType] = useState(btnName);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [formData, setFormData] = useState(null);
  // const [usage, setUsage] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  // Validation schema
  const dataQuestionSchema = yup.object().shape({
    Unit_Name: yup.string().required("Unit Name is required"),
    Brand: yup.string().required("Brand is required"),
    Model: yup.string(),
    PIN: yup.string(),
  });

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(dataQuestionSchema),
    defaultValues: {
      Unit_Name: fieldData?.Unit_Name || "",
      Brand: fieldData?.Brand || "",
      Model: fieldData?.Model || "",
      PIN: fieldData?.PIN || "",
    },
    mode: "onChange",
  });

  // Handle form submission
  const onSubmit = useCallback(() => {
    const contactPayload = {
      ...formData,
      // Usage: usage,
    };

    const handleError = (err) => {
      console.error(err);
      toast.error(err.response?.data?.errorMessage || "Internal server error");
    };

    switch (confirmationType) {
      case "add":
        axios
          .post("/printers", contactPayload)
          .then((res) => {
            setData((prev) => [...prev, res.data.data]);
            toast.success("Printer Added Successfully");
            reset();
            handleClose();
            setOpenConfirmation(false);
          })
          .catch(handleError);
        break;

      case "update":
        axios
          .put(`/printers/${fieldData?.PrinterID}`, contactPayload)
          .then((res) => {
            setData((prev) =>
              prev.map((item) =>
                item.PrinterID === fieldData?.PrinterID ? res.data.data : item
              )
            );
            toast.success("Printer Updated Successfully");
            reset();
            handleClose();
            setOpenConfirmation(false);
          })
          .catch(handleError);
        break;

      case "delete":
        axios
          .delete(`/printers/${fieldData?.PrinterID}`)
          .then(() => {
            setData((prev) =>
              prev.filter((item) => item.PrinterID !== fieldData?.PrinterID)
            );
            toast.success("Printer Deleted Successfully");
            reset();
            handleClose();
            setOpenConfirmation(false);
          })
          .catch(handleError);
        break;

      case "cancel":
        reset();
        handleClose();
        setOpenConfirmation(false);
        break;

      default:
        console.warn("Unknown button action:", btnName);
    }
  }, [
    btnName,
    confirmationType,
    fieldData?.PrinterID,
    formData,
    handleClose,
    reset,
    setData,
    // usage,
  ]);

  // Handle the form actions
  const formHandler = useCallback(
    (data) => {
      console.log(data);
      if (btnName === "add") {
        setConfirmationType("add");
        setFormData(data);
        setOpenConfirmation(true);
      } else if (btnName === "update") {
        setConfirmationType("update");
        setFormData(data);
        setOpenConfirmation(true);
      } else {
        setConfirmationType("delete");
        setOpenConfirmation(true);
      }
    },
    [btnName]
  );

  // Handle cancellation
  const handleCancel = useCallback(() => {
    if (isDirty || btnName === "delete") {
      setConfirmationType("cancel");
      setOpenConfirmation(true);
    } else {
      handleClose();
    }
  }, [isDirty, btnName, handleClose]);

  // Set button class and status based on the action
  const getButtonClassName = useCallback(() => {
    if (btnName === "delete") {
      return "py-2 px-6 md:px-16 bg-red-600 text-white rounded-xl min-w-36 cursor-pointer capitalize";
    }
    if (btnName === "add") {
      return `py-2 px-6 md:px-16 text-white ${
        !isValid
        // || !usage
          ? "bg-gray-400 text-gray-700 cursor-not-allowed"
          : "bg-BtnBg"
      } rounded-xl min-w-36 capitalize`;
    }
    if (btnName === "update") {
      return `py-2 px-6 md:px-16 text-white ${
        !isValid
        // || !usage
        || (!isDirty && !isUpdated)
          ? "bg-gray-400 text-gray-700 cursor-not-allowed"
          : "bg-BtnBg"
      } rounded-xl min-w-36 capitalize`;
    }
    return "py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 capitalize";
  }, [btnName, isValid,
    // usage,
    isDirty, isUpdated]);

  const getButtonStatus = useCallback(() => {
    switch (btnName) {
      case "add":
        // return !isValid || !usage;
        return !isValid;
      case "update":
        return !isValid
        // || !usage
        || (!isDirty && !isUpdated);
      default:
        return false;
    }
  }, [btnName, isValid,
    // usage,
    isDirty, isUpdated]);

  // Watch fieldData changes and reset form accordingly
  useEffect(() => {
    if (fieldData) {
      reset({
        Unit_Name: fieldData.Unit_Name,
        Brand: fieldData.Brand,
        Model: fieldData.Model,
        PIN: fieldData.PIN,
      });
      // setUsage(fieldData.Usage);
    }
  }, [fieldData, reset]);

  useEffect(() => {
    setConfirmationType(btnName);
  }, [btnName]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="crud-modal-title">
      <div className="bg-white w-11/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg shadow-xl max-w-[600px]">
        <form
          onSubmit={handleSubmit(formHandler)}
          className="small_scroller p-5 md:py-6 md:px-10 max-h-[90vh] overflow-y-auto"
        >
          <Box>
            <div className="flex flex-col">
              <h2
                id="crud-modal-title"
                className="text-xl font-semibold mb-2 text-BtnBg capitalize"
              >
                {btnName} Printer
              </h2>
              <div className="border-b border-gray-300 mb-4"></div>

              <TextField
                id="unitName"
                className="col-span-2 !mb-4 bg-[#f4f4f4]"
                label="Unit Name"
                variant="outlined"
                placeholder="Unit Name"
                {...register("Unit_Name")}
                error={!!errors.Unit_Name}
                helperText={errors.Unit_Name?.message}
                size="small"
                disabled={btnName === "delete"}
              />

              <TextField
                id="brand"
                className="col-span-2 !mb-4 bg-[#f4f4f4]"
                label="Brand"
                variant="outlined"
                placeholder="Brand"
                {...register("Brand")}
                error={!!errors.Brand}
                helperText={errors.Brand?.message}
                size="small"
                disabled={btnName === "delete"}
              />

              <TextField
                id="model"
                className="col-span-2 !mb-4 bg-[#f4f4f4]"
                label="Model"
                variant="outlined"
                placeholder="Model"
                {...register("Model")}
                error={!!errors.Model}
                helperText={errors.Model?.message}
                size="small"
                disabled={btnName === "delete"}
              />

              <TextField
                id="pin"
                className="col-span-2 !mb-4 bg-[#f4f4f4]"
                label="PIN"
                variant="outlined"
                placeholder="PIN"
                {...register("PIN")}
                error={!!errors.PIN}
                helperText={errors.PIN?.message}
                size="small"
                disabled={btnName === "delete"}
              />

              {/* <FormControl
                className="col-span-2 !mb-4 bg-[#f4f4f4]"
                size="small"
                disabled={btnName === "delete"}
              >
                <InputLabel id="usage-label">Usage</InputLabel>
                <Select
                  labelId="usage-label"
                  id="usage"
                  label="Usage"
                  value={usage}
                  onChange={(e) => {
                    setUsage(e.target.value);
                    setIsUpdated(true);
                  }}
                  error={!usage}
                >
                  <MenuItem value="Receive Inventory">
                    Receive Inventory
                  </MenuItem>
                  <MenuItem value="Shipping Out">Shipping Out</MenuItem>
                </Select>
              </FormControl> */}

              <div className="col-span-3 flex justify-end mt-5 gap-3">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="py-2 px-6 md:px-16 bg-BtnBg text-white rounded-xl min-w-36 cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={getButtonClassName()}
                  disabled={getButtonStatus()}
                >
                  {btnName}
                </button>
              </div>
            </div>
          </Box>
        </form>

        {/* Confirmation Modal */}
        <ConfirmationModal
          type={confirmationType}
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          onSubmit={onSubmit}
          from="Printer"
        />
      </div>
    </Modal>
  );
};

export default CrudModalThree;
