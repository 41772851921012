import React from "react";
import InfoCard from "../common/InfoCard";
import ContentCard from "./ContentCard";

import alertIcon from "../../assets/images/alertIcon.svg";
import warningIcon from "../../assets/images/warningIcon.svg";
import notificationIcon from "../../assets/images/notificationIcon.svg";
import SimplePieChart from "./Charts/PieChart";
import LineBarChart from "./Charts/LineBarChart";
import InventoryTableChart from "./Charts/InventoryTableChart";
import CustomBarChart from "./Charts/CustomBarChart";

const CommonComponentsDemo = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Common Components Demo</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">InfoCard</h2>
        <InfoCard primaryText="Primary Text" secondaryText="Secondary Text" />
        <InfoCard primaryText="Only Primary Text" />
        <InfoCard
          primaryText="Primary Text"
          secondaryText="Secondary Text"
          variant="outlined"
        />
        <InfoCard
          primaryText="Green Icon"
          iconColor="green"
          variant="outlined"
          icon={notificationIcon}
        />
        <InfoCard
          primaryText="Red Icon"
          secondaryText="Alert Status"
          iconColor="red"
          variant="outlined"
          icon={alertIcon}
        />
        <InfoCard
          variant="outlined"
          primaryText="Warnings"
          secondaryText="Active Alerts"
          iconColor="yellow"
          number={3}
          icon={warningIcon}
        />
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Content Card</h2>
        <ContentCard title="Empty Content Card" />
        <ContentCard title="Content card with Chart Component">
          <SimplePieChart />
        </ContentCard>
        <ContentCard
          title="Content card with Chart Component"
          showDateDropdown={true}
        >
          <LineBarChart />
        </ContentCard>
        <ContentCard title="Top Used Inventory" showDateDropdown={true}>
          <InventoryTableChart />
        </ContentCard>
        <ContentCard title="Top Used Inventory" showDateDropdown={true}>
          <CustomBarChart />
        </ContentCard>
      </section>
    </div>
  );
};

export default CommonComponentsDemo;
